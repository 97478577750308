<template>
  <b-form-group
    label="Proje"
    label-for="id_com_realty_project"
  >
    <validation-provider
      #default="{ errors }"
      name="Proje"
      rules="required"
    >
      <v-select
        id="id_com_realty_project"
        v-model="interviewLine.id_com_realty_project"
        :options="projects"
        :reduce="project => project.id"
        label="title"
        placeholder="Seçiniz"
        @input="getTypes"
      />
      <small class="text-danger">{{ errors[0] }}</small>
    </validation-provider>
  </b-form-group>
</template>

<script>
import { ValidationProvider, localize } from 'vee-validate'
// eslint-disable-next-line no-unused-vars
import { required } from '@validations'
import { BFormGroup } from 'bootstrap-vue'
import vSelect from 'vue-select'

export default {
  name: 'RealtyProjects',
  components: {
    BFormGroup,
    vSelect,
    ValidationProvider,
  },
  data() {
    return {
      locale: 'tr',
    }
  },
  computed: {
    interviewLine() {
      return this.$store.getters['interviews/getEditInterviewLine']
    },
    projects() {
      return this.$store.getters['realtyProjects/getRealty_sale_projects']
    },
  },
  created() {
    localize(this.locale)
  },
  mounted() {
    this.getProjects()
    this.getTypes()
  },
  methods: {
    getProjects() {
      this.$store.dispatch('realtyProjects/realty_sale_projectsList', {
        select: [
          'com_realty_project.id AS id',
          'com_realty_project.title AS title',
        ],
        where: {
          'com_realty_project.status': 1,
        },
      })
    },
    getTypes() {
      if (this.interviewLine.id_com_realty_project) {
        this.$store.dispatch('realtyTypes/realty_sale_typesList', {
          select: [
            'com_realty_type.id AS id',
            'com_realty_type.title AS title',
          ],
          where: {
            'com_realty_type.id_com_realty_project': this.interviewLine.id_com_realty_project,
          },
        })
      }
    },
  },
}
</script>

<style scoped>

</style>

<template>
  <div>
    <validation-observer ref="simpleRules">
      <b-row>
        <b-col
          xs="12"
          md="4"
        >
          <b-card title="Gayrimenkul Satış Görüşmesi">
            <meets />
          </b-card>
        </b-col>
        <b-col>
          <b-card title="Görüşme Detay">
            <interview-statuses
              v-if="interview.id_com_interview_type"
              :id_com_interview_type="interview.id_com_interview_type"
              first-status="0"
            />
            <b-row v-if="interview.id_com_interview_status === '25'">
              <b-col cols="12">
                <realty-sale-status />
              </b-col>
              <b-col cols="6">
                <realty-projects />
              </b-col>
              <b-col cols="6">
                <realty-types />
              </b-col>
              <b-col cols="6">
                <realty-blocks />
              </b-col>
              <b-col cols="6">
                <realty-numbers />
              </b-col>
              <b-col
                v-if="interview.id_com_realty_number"
                cols="6"
              >
                <realty-list-price />
              </b-col>
              <b-col
                v-if="interview.id_com_realty_number"
                cols="6"
              >
                <realty-sale-price />
              </b-col>
            </b-row>
            <interview-content />
            <new-alert />
            <save-button :action-methods="submitForm" />
          </b-card>
        </b-col>
      </b-row>
    </validation-observer>
    <add-expertise />
  </div>
</template>

<script>
import { ValidationObserver, localize } from 'vee-validate'
// eslint-disable-next-line no-unused-vars
import { required } from '@validations'
import { BCard, BRow, BCol } from 'bootstrap-vue'
import Meets from '@/views/Interviews/forms/Meets.vue'
import InterviewStatuses from '@/views/Interviews/forms/Interview_statuses.vue'
import InterviewContent from '@/views/Interviews/forms/Content.vue'
import NewAlert from '@/views/Interviews/forms/NewAlert.vue'
import RealtyProjects from '@/views/Interviews/forms/RealtyProjects.vue'
import RealtyTypes from '@/views/Interviews/forms/RealtyTypes.vue'
import RealtyBlocks from '@/views/Interviews/forms/RealtyBlocks.vue'
import RealtyNumbers from '@/views/Interviews/forms/RealtyNumbers.vue'
import RealtySaleStatus from '@/views/Interviews/forms/RealtySaleStatus.vue'
import RealtyListPrice from '@/views/Interviews/forms/RealtyListPrice.vue'
import RealtySalePrice from '@/views/Interviews/forms/RealtySalePrice.vue'
import SaveButton from '@/views/Interviews/forms/SaveButton.vue'
import AddExpertise from '@/views/Interviews/components/AddExpertise.vue'

export default {
  name: 'NewLine',
  components: {
    ValidationObserver,
    BCard,
    BRow,
    BCol,
    Meets,
    InterviewStatuses,
    InterviewContent,
    NewAlert,
    RealtyProjects,
    RealtyTypes,
    RealtyBlocks,
    RealtyNumbers,
    RealtySaleStatus,
    RealtyListPrice,
    RealtySalePrice,
    SaveButton,
    AddExpertise,
  },
  computed: {
    interview() {
      return this.$store.getters['interviews/getInterview']
    },
    saveStatus() {
      return this.$store.getters['interviews/getInterviewSaveStatus']
    },
  },
  watch: {
    saveStatus(val) {
      if (val.status === true) {
        this.interview.id_com_meet = null
        this.interview.testdrive = null
        this.interview.expertise_type = null
        this.interview.id_com_interview_status = null
        this.interview.content = null
      }
      this.$refs.simpleRules.reset()
    },
  },
  created() {
    localize('tr')
  },
  methods: {
    submitForm() {
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          this.$store.dispatch('interviews/interviewSave', this.interview)
        }
      })
    },
  },
}
</script>

<style scoped>

</style>

<template>
  <b-form-group
    label="Blok"
    label-for="id_com_realty_block"
  >
    <validation-provider
      #default="{ errors }"
      name="Blok"
      rules="required"
    >
      <v-select
        id="id_com_realty_block"
        v-model="interview.id_com_realty_block"
        :options="blocks"
        :reduce="block => block.id"
        label="title"
        placeholder="Seçiniz"
        @input="getNumbers"
      />
      <small class="text-danger">{{ errors[0] }}</small>
    </validation-provider>
  </b-form-group>
</template>

<script>
import { ValidationProvider, localize } from 'vee-validate'
// eslint-disable-next-line no-unused-vars
import { required } from '@validations'
import { BFormGroup } from 'bootstrap-vue'
import vSelect from 'vue-select'

export default {
  name: 'RealtyBlocks',
  components: {
    BFormGroup,
    vSelect,
    ValidationProvider,
  },
  computed: {
    interview() {
      return this.$store.getters['interviews/getInterview']
    },
    blocks() {
      return this.$store.getters['realtyBlocks/getRealty_sale_blocks']
    },
  },
  created() {
    localize('tr')
  },
  methods: {
    getNumbers() {
      this.interview.id_com_realty_number = null
      this.$store.dispatch('realtyNumbers/realty_sale_available_numbersList', {
        id_com_realty_block: this.interview.id_com_realty_block,
        id_com_realty_type: this.interview.id_com_realty_type,
        id_com_realty_project: this.interview.id_com_realty_project,
      })
    },
  },
}
</script>

<style scoped>

</style>

<template>
  <div>
    <b-card
      v-if="interview.poll.result"
      title="Anket Sonucu"
    >
      <b-list-group>
        <b-list-group-item
          v-for="(item,key) in interview.poll.result"
          :key="key"
          class="d-flex d-inline-flex justify-content-between"
        >
          <div>{{ item.question }}</div>
          <div class="text-right">
            <b-badge
              variant="warning"
            >
              {{ item.answer }} Puan
            </b-badge>
          </div>
        </b-list-group-item>
        <b-list-group-item class="d-flex d-inline-flex justify-content-between bg-gradient-warning">
          <div class="font-weight-bold text-light font-medium-2">
            Anket Ortalaması
          </div>
          <div class="font-weight-bold font-medium-2">
            <b-badge
              variant="primary"
            >
              {{ interview.poll.scoreAvg | toNumber }} Puan
            </b-badge>
          </div>
        </b-list-group-item>
      </b-list-group>
    </b-card>
  </div>
</template>

<script>
import {
  BCard, BListGroup, BListGroupItem, BBadge,
} from 'bootstrap-vue'

export default {
  name: 'PollView',
  components: {
    BCard, BListGroup, BListGroupItem, BBadge,
  },
  computed: {
    interview() {
      return this.$store.getters['interviews/getViewInterview']
    },
  },
}
</script>

<style scoped>

</style>

<template>
  <div>
    <b-card no-body>
      <b-card-body class="pb-0">
        <b-card-title>
          <b-row>
            <b-col>
              <div>#{{ interview.interview_number }}</div>
              <small>{{ interview.company? interview.company + ' ' + interview.customer : interview.customer }}
                - <span class="text-warning">{{ interview.username }}</span>
              </small>
            </b-col>
            <b-col cols="auto">
              <b-button
                variant="primary"
                :href="downloadPDF"
                target="_blank"
              >
                <FeatherIcon icon="PrinterIcon" />
                Görüşmeyi Yazdır
              </b-button>
            </b-col>
          </b-row>
        </b-card-title>
      </b-card-body>
      <b-row>
        <b-col xs="12">
          <b-table-simple
            fixed
          >
            <b-thead>
              <b-tr>
                <b-th>
                  Görüşme Tipi
                </b-th>
                <b-th>
                  Proje
                </b-th>
                <b-th>
                  Gayrimenkul Tipi
                </b-th>
                <b-th>
                  İlk Temas
                </b-th>
                <b-th>
                  Durum Kodu
                </b-th>
                <b-th>
                  Durum
                </b-th>
              </b-tr>
            </b-thead>
            <b-tbody>
              <b-tr>
                <b-td>{{ interview.interview_type }}</b-td>
                <b-td>{{ interview.realty_project }}</b-td>
                <b-td>{{ interview.realty_type }}</b-td>
                <b-td>{{ interview.meet }}</b-td>
                <b-td>{{ interview.status_code }}</b-td>
                <b-td>{{ interview.statusValue }}</b-td>
              </b-tr>
            </b-tbody>
          </b-table-simple>
        </b-col>
      </b-row>
    </b-card>
  </div>
</template>
<script>
import {
  BCard, BCardTitle, BCardBody, BTableSimple, BThead, BTbody, BTr, BTh, BTd, BRow, BCol, BButton,
} from 'bootstrap-vue'

export default {
  name: 'InterviewSummary',
  components: {
    BCard, BCardTitle, BCardBody, BTableSimple, BThead, BTbody, BTr, BTh, BTd, BRow, BCol, BButton,
  },
  computed: {
    interview() {
      return this.$store.getters['interviews/getViewInterview']
    },
    downloadPDF() {
      const urlParams = [
        `&interview_number=${this.interview.interview_number}`,
      ].join('&')
      return `${this.$store.state.app.baseURL}/exports/realty_sale/interview/pdf?token=${localStorage.getItem('downloadToken')}${urlParams}`
    },
  },
}
</script>

<style scoped>

</style>

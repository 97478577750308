<template>
  <div>
    <b-card no-body>
      <b-table-simple
        striped
        hover
      >
        <b-thead>
          <b-tr>
            <b-th>
              Görüşme Tarihi
            </b-th>
            <b-th>
              Durum
            </b-th>
            <b-th>
              Temas
            </b-th>
            <b-th colspan="2">
              Görüşme Notu
            </b-th>
          </b-tr>
        </b-thead>
        <b-tbody>
          <b-tr
            v-for="(line,key) in interview.lines"
            :key="key"
          >
            <b-td class="text-nowrap">
              {{ moment(line.idate + ' ' + line.itime).format('LLL') }}
            </b-td>
            <b-td class="text-nowrap">
              {{ line.interview_status }}
            </b-td>
            <b-td class="text-nowrap">
              {{ line.meet }}
            </b-td>
            <b-td>
              <div>
                {{ line.content }}
              </div>
              <b-link
                v-if="line.id_com_swapcar"
                class="d-flex align-items-center font-small-2 text-danger"
                :href="baseUrl + '/exports/data/expertise/pdf?id=' + line.id_com_swapcar + '&token=' + downloadToken"
                target="_blank"
              >
                Takas Araç PDF İndir
              </b-link>
              <b-link
                v-if="line.id_com_testdrive_form_sms"
                class="d-flex align-items-center font-small-2 text-primary"
                :href="baseUrl + '/exports/data/testdrive/pdf?id=' + line.id_com_testdrive_form_sms + '&token=' + downloadToken"
                target="_blank"
              >
                Test Sürüşü Formu İndir
              </b-link>
              <div
                v-if="line.modified"
                class="text-info font-small-2"
              >
                {{ moment(line.modified).format('LLL') }} tarihinde güncellendi.
              </div>
            </b-td>
            <b-td class="text-right">
              <b-button
                v-if="userData.id === line.id_com_user"
                v-b-modal.edit-modal
                class="btn-icon"
                variant="primary"
                pill
                @click="setEditLine(line)"
              >
                <FeatherIcon icon="EditIcon" />
              </b-button>
            </b-td>
          </b-tr>
        </b-tbody>
      </b-table-simple>
    </b-card>
    <edit-modal />
  </div>
</template>
<script>
import {
  BCard, BTableSimple, BThead, BTbody, BTr, BTh, BTd, BButton, BLink,
} from 'bootstrap-vue'
import EditModal from '@/views/Interviews/components/view/Sales/EditModal.vue'

export default {
  name: 'InterviewSummary',
  components: {
    BCard, BTableSimple, BThead, BTbody, BTr, BTh, BTd, BButton, BLink, EditModal,
  },
  data() {
    return {
      downloadToken: localStorage.getItem('downloadToken'),
      baseUrl: this.$store.state.app.baseURL,
      userData: JSON.parse(localStorage.getItem('userData')),
    }
  },
  computed: {
    interview() {
      return this.$store.getters['interviews/getViewInterview']
    },
    interviewLine() {
      return this.$store.getters['interviews/getEditInterviewLine']
    },
  },
  methods: {
    setEditLine(data) {
      this.$store.dispatch('interviews/setEditLine', data)
    },
  },
}
</script>
<style scoped></style>

<template>
  <b-form-group
    label="Kapı No"
    label-for="id_com_realty_number"
  >
    <validation-provider
      #default="{ errors }"
      name="Kapı No"
      rules="required"
    >
      <v-select
        id="id_com_realty_number"
        v-model="interview.id_com_realty_number"
        :options="availableNumbers"
        label="title"
        placeholder="Seçiniz"
        :selectable="(option) => !option.locked === true"
      >
        <template v-slot:option="option">
          <div>
            Kapı No: {{ option.title }}
          </div>
          <div>
            <small class="text-info">{{ option.status }}</small>
          </div>
          <div>
            <small class="text-warning">{{ option.price | toCurrency }} ₺</small>
          </div>
        </template>
        <template v-slot:selected-option="option">
          <div>
            Kapı No: {{ option.title }} <span class="text-success">{{ option.status }}</span>
          </div>
        </template>
      </v-select>
      <small class="text-danger">{{ errors[0] }}</small>
    </validation-provider>
  </b-form-group>
</template>

<script>
import { ValidationProvider, localize } from 'vee-validate'
// eslint-disable-next-line no-unused-vars
import { required } from '@validations'
import { BFormGroup } from 'bootstrap-vue'
import vSelect from 'vue-select'

export default {
  name: 'RealtyNumbers',
  components: {
    BFormGroup,
    vSelect,
    ValidationProvider,
  },
  computed: {
    interview() {
      return this.$store.getters['interviews/getInterview']
    },
    availableNumbers() {
      return this.$store.getters['realtyNumbers/getRealty_sale_available_numbers']
    },
  },
  created() {
    localize('tr')
  },
}
</script>

<style scoped>

</style>

<template>
  <div>
    <validation-observer ref="simpleRules">
      <b-row>
        <b-col
          xs="12"
          md="4"
        >
          <b-card title="Sıfır Satış Görüşmesi">
            <meets />
            <test-drive />
            <expertise />
          </b-card>
        </b-col>
        <b-col>
          <b-card title="Görüşme Detay">
            <interview-statuses
              v-if="interview.id_com_interview_type"
              :id_com_interview_type="interview.id_com_interview_type"
              first-status="0"
            />
            <interview-content />
            <new-alert />
            <save-button
              :disabled="interview.testdrive > 0 && !interview.id_com_testdrive_form_sms"
              :action-methods="submitForm"
            />
          </b-card>
        </b-col>
      </b-row>
    </validation-observer>
    <add-expertise />
  </div>
</template>

<script>
import { ValidationObserver, localize } from 'vee-validate'
// eslint-disable-next-line no-unused-vars
import { required } from '@validations'
import { BCard, BRow, BCol } from 'bootstrap-vue'
import Meets from '@/views/Interviews/forms/Meets.vue'
import TestDrive from '@/views/Interviews/forms/TestDrive.vue'
import Expertise from '@/views/Interviews/forms/Expertise.vue'
import InterviewStatuses from '@/views/Interviews/forms/Interview_statuses.vue'
import InterviewContent from '@/views/Interviews/forms/Content.vue'
import NewAlert from '@/views/Interviews/forms/NewAlert.vue'
import SaveButton from '@/views/Interviews/forms/SaveButton.vue'
import AddExpertise from '@/views/Interviews/components/AddExpertise.vue'

export default {
  name: 'NewLine',
  components: {
    ValidationObserver,
    BCard,
    BRow,
    BCol,
    Meets,
    TestDrive,
    Expertise,
    InterviewStatuses,
    InterviewContent,
    NewAlert,
    SaveButton,
    AddExpertise,
  },
  computed: {
    interview() {
      return this.$store.getters['interviews/getInterview']
    },
    saveStatus() {
      return this.$store.getters['interviews/getInterviewSaveStatus']
    },
    models() {
      return this.$store.getters['models/getModels']
    },
  },
  watch: {
    saveStatus(val) {
      if (val.status === true) {
        this.interview.id_com_meet = null
        this.interview.testdrive = null
        this.interview.expertise_type = null
        this.interview.id_com_interview_status = null
        this.interview.content = null
        this.interview.id_com_swapcar = null
      }
      this.$refs.simpleRules.reset()
    },
  },
  created() {
    localize('tr')
    this.getModels()
  },
  methods: {
    submitForm() {
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          this.interview.submitStatus = true
          this.$store.dispatch('interviews/interviewSave', this.interview)
            .then(res => {
              if (res) {
                this.interview.submitStatus = false
                this.interview.id_com_testdrive_form_sms = null
                this.interview.testdrive = null
                if (res.orderNumber) {
                  this.$router.push(`/sale_orderforms/add/${res.orderNumber}`)
                }
              }
            })
        }
      })
    },
    getModels() {
      if (!this.models.length) {
        this.$store.dispatch('models/modelsList', {
          select: [
            'com_model.id AS id',
            'com_model.title AS title',
          ],
          where: {
            'com_model.id_com_brand': this.interview.id_com_brand,
          },
        })
      }
    },
  },
}
</script>

<template>
  <b-form-group
    label="Liste Fiyatı"
    label-for="listprice"
  >
    <cleave
      id="request_price"
      v-model="interview.id_com_realty_number.price"
      class="form-control"
      :raw="true"
      :options="options.price"
      placeholder="Liste Fiyatı"
      readonly
    />
  </b-form-group>
</template>

<script>
import {
  BFormGroup,
} from 'bootstrap-vue'
import Cleave from 'vue-cleave-component'

export default {
  name: 'RealtyListPrice',
  components: {
    BFormGroup,
    Cleave,
  },
  data() {
    return {
      options: {
        price: {
          numeral: true,
          numeralThousandsGroupStyle: 'thousand',
          numeralDecimalMark: ',',
          delimiter: '.',
        },
      },
    }
  },
  computed: {
    interview() {
      return this.$store.getters['interviews/getInterview']
    },
  },
}
</script>

<template>
  <b-form-group
    label="Satış Durumu"
    label-for="id_com_realty_sale_status"
  >
    <validation-provider
      #default="{ errors }"
      name="Satış Durumu"
      rules="required"
    >
      <v-select
        id="id_com_realty_sale_status"
        v-model="interview.id_com_realty_sale_status"
        :options="statuses"
        :reduce="status => status.id"
        label="title"
        placeholder="Seçiniz"
      />
      <small class="text-danger">{{ errors[0] }}</small>
    </validation-provider>
  </b-form-group>
</template>

<script>
import { ValidationProvider, localize } from 'vee-validate'
// eslint-disable-next-line no-unused-vars
import { required } from '@validations'
import { BFormGroup } from 'bootstrap-vue'
import vSelect from 'vue-select'

export default {
  name: 'RealtyProjects',
  components: {
    BFormGroup,
    vSelect,
    ValidationProvider,
  },
  computed: {
    interview() {
      return this.$store.getters['interviews/getInterview']
    },
    statuses() {
      return this.$store.getters['realtySaleStatuses/getRealty_sale_statuses']
    },
  },
  created() {
    localize('tr')
    this.getStatuses()
  },
  methods: {
    getStatuses() {
      this.$store.dispatch('realtySaleStatuses/realty_sale_statusesList', {
        select: [
          'com_realty_sale_status.id AS id',
          'com_realty_sale_status.title AS title',
        ],
        where: {
          'com_realty_sale_status.id <': 3,
        },
      })
    },
  },
}
</script>

<style scoped>

</style>

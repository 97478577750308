<template>
  <div>
    <b-card no-body>
      <b-card-body class="pb-0">
        <b-card-title>
          #{{ interview.interview_number }}
          <small>{{ interview.company? interview.company + ' ' + interview.customer : interview.customer }}
            - <span class="text-warning">{{ interview.username }}</span>
          </small>
        </b-card-title>
      </b-card-body>
      <b-row>
        <b-col xs="12">
          <b-table-simple
            fixed
          >
            <b-thead>
              <b-tr>
                <b-th>
                  Görüşme Tipi
                </b-th>
                <b-th>
                  Marka
                </b-th>
                <b-th>
                  Araç Bilgisi
                </b-th>
                <b-th>
                  Konu
                </b-th>
                <b-th>
                  İlk Temas
                </b-th>
                <b-th>
                  Durum
                </b-th>
              </b-tr>
            </b-thead>
            <b-tbody>
              <b-tr>
                <b-td>{{ interview.interview_type }}</b-td>
                <b-td>{{ interview.brand }}</b-td>
                <b-td>{{ interview.carInfo }}</b-td>
                <b-td>{{ interview.interview_subject }}</b-td>
                <b-td>{{ interview.meet }}</b-td>
                <b-td>{{ interview.statusValue }}</b-td>
              </b-tr>
            </b-tbody>
          </b-table-simple>
        </b-col>
      </b-row>
    </b-card>
  </div>
</template>
<script>
import {
  BCard, BCardTitle, BCardBody, BTableSimple, BThead, BTbody, BTr, BTh, BTd, BRow, BCol,
} from 'bootstrap-vue'

export default {
  name: 'InterviewSummary',
  components: {
    BCard, BCardTitle, BCardBody, BTableSimple, BThead, BTbody, BTr, BTh, BTd, BRow, BCol,
  },
  computed: {
    interview() {
      return this.$store.getters['interviews/getViewInterview']
    },
  },
}
</script>

<style scoped>

</style>

<template>
  <div>
    <interview-summary />
    <interview-lines />
    <new-line
      v-if="(interview.id_com_user === userData.id) && (interview.status === '0')"
    />
  </div>
</template>

<script>
import InterviewSummary from '@/views/Interviews/components/view/RealtySales/InterviewSummary.vue'
import InterviewLines from '@/views/Interviews/components/view/RealtySales/InterviewLines.vue'
import NewLine from '@/views/Interviews/components/view/RealtySales/NewLine.vue'

export default {
  name: 'RealtySales',
  components: {
    InterviewSummary,
    InterviewLines,
    NewLine,
  },
  data() {
    return {
      userData: JSON.parse(localStorage.getItem('userData')),
    }
  },
  computed: {
    interview() {
      return this.$store.getters['interviews/getViewInterview']
    },
  },
}
</script>

<style scoped>

</style>
